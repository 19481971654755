var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { style: _vm.backCss, attrs: { spinning: _vm.loading } },
    [
      _c(
        "div",
        { staticClass: "actions flex jc-end mb-4" },
        [
          _c("div", { staticStyle: { flex: "1" } }, [
            _c(
              "div",
              { staticStyle: { width: "500px" } },
              [
                _c(
                  "a-row",
                  { attrs: { type: "flex", gutter: 16 } },
                  [
                    _c(
                      "a-col",
                      [
                        _c("a-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            placeholder: "请输入原文或者译文",
                            "allow-clear": "",
                          },
                          on: { pressEnter: _vm.searchQuery },
                          model: {
                            value: _vm.query,
                            callback: function ($$v) {
                              _vm.query = $$v
                            },
                            expression: "query",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary", icon: "search" },
                            on: { click: _vm.searchQuery },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.selectRows.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:termtemplate:back:delete",
                      expression: "'production:termtemplate:back:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-dropdown",
            { attrs: { trigger: ["click"] } },
            [
              _vm.checkPermission() ||
              (_vm.userInfo.positions &&
                _vm.userInfo.positions.some(
                  (item) =>
                    item.name == "翻译" ||
                    item.name == "校对" ||
                    item.name == "终审" ||
                    item.name == "制作" ||
                    item.name == "监制"
                ))
                ? _c("a-button", { attrs: { type: "primary", icon: "plus" } }, [
                    _vm._v("添加"),
                  ])
                : _vm._e(),
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c(
                    "a-menu-item",
                    {
                      key: "0",
                      attrs: { disabled: true },
                      on: {
                        click: function ($event) {
                          return _vm.addBack("背景文字")
                        },
                      },
                    },
                    [_vm._v("\n          背景文字\n        ")]
                  ),
                  _c(
                    "a-menu-item",
                    {
                      key: "1",
                      attrs: {
                        disabled:
                          _vm.userInfo.positions &&
                          _vm.userInfo.positions.some(
                            (item) =>
                              item.name == "翻译" ||
                              item.name == "校对" ||
                              item.name == "终审"
                          ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addBack("特殊字体")
                        },
                      },
                    },
                    [_vm._v("\n          特殊字体\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { height: "32px" } }),
        ],
        1
      ),
      _c(
        "vxe-table",
        {
          ref: "xTree",
          attrs: {
            border: "none",
            "row-config": { isCurrent: true, isHover: true, height: 80 },
            "column-config": { resizable: true },
            "tree-config": {
              hasChild: "hasChild",
              parentField: "parentId",
              rowField: "teamId",
            },
            data: _vm.tableData,
          },
          on: {
            "checkbox-change": _vm.selectChangeEvent,
            "checkbox-all": _vm.selectChangeEvent,
            "cell-click": _vm.cellClickEvent,
            "toggle-tree-expand": _vm.toggleExpandChangeEvent,
          },
        },
        [
          _c("vxe-column", {
            attrs: {
              align: "center",
              fixed: "left",
              type: "checkbox",
              minWidth: "80",
              "show-overflow": "",
            },
          }),
          _vm._l(_vm.columns, function (record) {
            return _c("vxe-column", {
              key: record.dataIndex,
              attrs: {
                fixed: record.dataIndex == "team" ? "left" : "",
                field: record.dataIndex,
                title: record.title,
                minWidth: record.width,
                "tree-node": record.dataIndex == "team",
                "show-overflow": "",
              },
              scopedSlots: _vm._u(
                [
                  record.dataIndex == "team"
                    ? {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.hasChild
                              ? _c("span", [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(row.team) +
                                      "\n        "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(row.chapterNo) +
                                      "\n        "
                                  ),
                                ]),
                          ]
                        },
                      }
                    : record.dataIndex == "originImgUrl"
                    ? {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", [
                              _c("img", {
                                staticStyle: { width: "auto", height: "45px" },
                                attrs: { src: row.originImgUrl, alt: "" },
                              }),
                            ]),
                          ]
                        },
                      }
                    : record.dataIndex == "originContent"
                    ? {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "span",
                              { style: !row.textAreaId ? "color:red" : "" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(row.originContent) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ]
                        },
                      }
                    : record.dataIndex == "processedContent"
                    ? {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "span",
                              { style: !row.textAreaId ? "color:red" : "" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(row.processedContent) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ]
                        },
                      }
                    : record.dataIndex == "processedImgUrl"
                    ? {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", [
                              _c("img", {
                                staticStyle: { width: "auto", height: "45px" },
                                attrs: { src: row.processedImgUrl, alt: "" },
                              }),
                            ]),
                          ]
                        },
                      }
                    : record.dataIndex == "tyoeface"
                    ? {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.hasChild && row.total > 50
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { width: "220px" },
                                    attrs: { "click.stop": "" },
                                  },
                                  [
                                    _c("a-pagination", {
                                      attrs: {
                                        size: "small",
                                        showLessItems: true,
                                        defaultPageSize: 50,
                                        "default-current": 1,
                                        total: row.productTotal - 0,
                                      },
                                      on: {
                                        change: (page, pageSize) => {
                                          _vm.treeTablePageChange(
                                            page,
                                            pageSize,
                                            row
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v(_vm._s(row.tyoeface))]),
                          ]
                        },
                      }
                    : null,
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("terms-back-modal", {
        attrs: {
          open: _vm.drawerShow,
          addType: _vm.addType,
          parent: _vm.parent,
          setTitle: _vm.setTitle,
          userInfo: _vm.userInfo,
          isAdmin: _vm.isAdmin,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
        },
        on: { closed: _vm.drawerClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
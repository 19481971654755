<!-- 作品详情术语分类 -->
<template>
  <a-spin :spinning="loading" :style="backCss">
    <div class="actions flex jc-end mb-4">
      <div style="flex:1">
        <div style="width:500px">
          <a-row type="flex" :gutter="16">
            <a-col>
              <a-input
                style="width:350px"
                placeholder="请输入原文或者译文"
                v-model="query"
                allow-clear
                @pressEnter="searchQuery"
              >
              </a-input>
            </a-col>
            <a-col>
              <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectRows.length"
        icon="delete"
        v-has="'production:termtemplate:back:delete'"
        >删除</a-button
      >
      <a-dropdown :trigger="['click']">
        <a-button
          type="primary"
          v-if="
            checkPermission() ||
              (userInfo.positions &&
                userInfo.positions.some(
                  item =>
                    item.name == '翻译' ||
                    item.name == '校对' ||
                    item.name == '终审' ||
                    item.name == '制作' ||
                    item.name == '监制'
                ))
          "
          icon="plus"
          >添加</a-button
        >
        <a-menu slot="overlay">
          <a-menu-item key="0" @click="addBack('背景文字')" :disabled="true">
            背景文字
          </a-menu-item>
          <a-menu-item
            :disabled="
              userInfo.positions &&
                userInfo.positions.some(item => item.name == '翻译' || item.name == '校对' || item.name == '终审')
            "
            key="1"
            @click="addBack('特殊字体')"
          >
            特殊字体
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <div style="height: 32px"></div>
    </div>

    <vxe-table
      border="none"
      ref="xTree"
      :row-config="{ isCurrent: true, isHover: true, height: 80 }"
      :column-config="{ resizable: true }"
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectChangeEvent"
      @cell-click="cellClickEvent"
      @toggle-tree-expand="toggleExpandChangeEvent"
      :tree-config="{
        hasChild: 'hasChild',
        parentField: 'parentId',
        rowField: 'teamId'
      }"
      :data="tableData"
    >
      <vxe-column align="center" fixed="left" type="checkbox" minWidth="80" show-overflow> </vxe-column>

      <vxe-column
        v-for="record in columns"
        :fixed="record.dataIndex == 'team' ? 'left' : ''"
        :key="record.dataIndex"
        :field="record.dataIndex"
        :title="record.title"
        :minWidth="record.width"
        :tree-node="record.dataIndex == 'team'"
        show-overflow
      >
        <template v-if="record.dataIndex == 'team'" v-slot="{ row }">
          <span v-if="row.hasChild">
            {{ row.team }}
          </span>
          <span v-else>
            {{ row.chapterNo }}
          </span>
        </template>
        <template v-else-if="record.dataIndex == 'originImgUrl'" v-slot="{ row }">
          <span>
            <img :src="row.originImgUrl" style="width:auto;height:45px" alt="" />
          </span>
        </template>
        <template v-else-if="record.dataIndex == 'originContent'" v-slot="{ row }">
          <span :style="!row.textAreaId ? 'color:red' : ''">
            {{ row.originContent }}
          </span>
        </template>
        <template v-else-if="record.dataIndex == 'processedContent'" v-slot="{ row }">
          <span :style="!row.textAreaId ? 'color:red' : ''">
            {{ row.processedContent }}
          </span>
        </template>
        <template v-else-if="record.dataIndex == 'processedImgUrl'" v-slot="{ row }">
          <span>
            <img :src="row.processedImgUrl" style="width:auto;height:45px" alt="" />
          </span>
        </template>
        <template v-else-if="record.dataIndex == 'tyoeface'" v-slot="{ row }">
          <div click.stop v-if="row.hasChild && row.total > 50" style="width:220px;">
            <a-pagination
              size="small"
              :showLessItems="true"
              :defaultPageSize="50"
              :default-current="1"
              :total="row.productTotal - 0"
              @change="
                (page, pageSize) => {
                  treeTablePageChange(page, pageSize, row)
                }
              "
            />
          </div>
          <div v-else>{{ row.tyoeface }}</div>
        </template>
      </vxe-column>
    </vxe-table>
    <terms-back-modal
      :open="drawerShow"
      :addType="addType"
      :parent="parent"
      :setTitle="setTitle"
      :userInfo="userInfo"
      :isAdmin="isAdmin"
      @closed="drawerClosed"
      :cur-row="curRow"
      :isForm="isNewForm"
    />
  </a-spin>
</template>

<script>
import { getAction, postAction, deleteAction } from '@/api/manage'
import termsBackModal from './terms-back-modal'
import { checkPermission } from '@/utils/hasPermission'
export default {
  components: {
    termsBackModal
  },
  props: {
    parent: {
      type: Object,
      default: {}
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    tab: {
      type: String,
      default: '1'
    }
  },
  watch: {
    tab(n) {
      this.loadData()
    }
  },
  data() {
    return {
      selectedRowKeys: [],
      setTitle: '添加',
      loading: false,
      query: '',
      columns: [
        {
          title: '章节序号',
          dataIndex: 'team',
          width: 200
        },
        {
          title: '原文图片',
          width: 200,
          dataIndex: 'originImgUrl'
        },
        {
          title: '原文',
          width: 200,
          dataIndex: 'originContent'
        },
        {
          title: '译文',
          width: 200,
          dataIndex: 'processedContent'
        },
        {
          title: '文件名',
          width: 200,
          dataIndex: 'fileName'
        },
        {
          title: '制作后图片',
          width: 200,
          dataIndex: 'processedImgUrl'
        },
        {
          title: '字体',
          width: 240,
          dataIndex: 'typeface'
        }
      ],
      tableData: [],
      selectRows: [],
      color: '#FF6680',
      bjCurrent: 1,
      tsCurrent: 1,
      drawerShow: false,
      isNewForm: false,
      curRow: {},
      addType: ''
    }
  },
  methods: {
    checkPermission() {
      return checkPermission('production:termtemplate:back:add')
    },
    batchDel: function() {
      let data = []
      let that = this
      if (!this.selectRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/backgroundBook/delete?ids=' + that.selectRows.map(item => item.id).join(','))
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.loadData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    addBack(type) {
      this.addType = type
      this.drawerShow = true
      this.isNewForm = true
      this.setTitle = '添加'
    },
    searchQuery() {
      this.loadData()
    },
    treeTablePageChange(page, pageSize, row) {
      if (row.team == '背景文字') {
        this.bjCurrent = page
      } else {
        this.tsCurrent = page
      }
      this.loadData()
      setTimeout(() => {
        this.$refs.xTree.reloadTreeExpand(row)
      }, 0)
    },
    async loadData() {
      this.loading = true
      this.tableData = []
      const res = await postAction('/backgroundBook/page/group', [
        { type: '背景文字', size: '-1', current: this.bjCurrent, productionId: this.parent.id, query: this.query },
        { type: '特殊字体', size: '-1', current: this.tsCurrent, productionId: this.parent.id, query: this.query }
      ])
      if (res.code == 200) {
        if (res.data['背景文字'].records.length || res.data['特殊字体'].records.length) {
          this.tableData = [
            {
              value: 'bj',
              teamId: 'bj',
              team: '背景文字',
              children: res.data['背景文字'].records?.map(item => item.backgroundBook),
              hasChild: true,
              total: res.data['背景文字'].total
            },
            {
              value: 'ts',
              teamId: 'ts',
              team: '特殊字体',
              children: res.data['特殊字体'].records?.map(item => item.backgroundBook),
              hasChild: true,
              total: res.data['特殊字体'].total
            }
          ]
        }
        this.selectRows=[]
        setTimeout(() => {
          if (this.query) {
            this.$refs.xTree.setAllTreeExpand(true)
          }
        }, 0)

        this.loading = false
      } else {
        this.$message.error(res.msg)
      }
    },
    openModal(row) {
      this.curRow = JSON.parse(JSON.stringify(row)) //防止在drawer中修改内容会影响列表
      console.log({ 'openDetail this.curRow': this.curRow })
      this.drawerShow = true
      this.isNewForm = false
    },
    toggleExpandChangeEvent({ row, expanded }) {
      this.$refs.xTree.toggleTreeExpand(row)
      if (this.$refs.xTree) this.$refs.xTree.updateData()
      return
    },
    cellClickEvent(e) {
      console.log(e._columnIndex)
      if (!e._columnIndex) return
      if (!e.row.hasChild && e._columnIndex != '1') {
        this.openModal(e.row)
        return
      }
      if (e.row.expend) {
        e.row.expend = false
        this.$refs.xTree.setTreeExpand(e.row, false)
      } else {
        e.row.expend = true
        this.$refs.xTree.setTreeExpand(e.row, true)
      }
      if (this.$refs.xTree) this.$refs.xTree.updateData()
      return
    },
    selectChangeEvent() {
      const records = this.$refs.xTree.getCheckboxRecords()
      this.$emit(
        'setRowkeys',
        records.filter(item => !item.hasChild)
      )
      this.selectRows = records.filter(item => !item.hasChild)
      this.$refs.xTree.updateFooter() //手动更新表尾
    },
    drawerClosed() {
      this.drawerShow = false
      this.loadData()
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    console.log(this.userInfo)
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
            localStorage.setItem(
              'color',
              JSON.stringify({ hoverColor: this.color, color: this.$store.state.app.color })
            )
          }
        }
      }
    }, 50)
  },
  computed: {
    backCss() {
      let colorBack = this.color
      let color = this.$store.state.app.color
      return {
        '--back-color': colorBack,
        '--theme-color': color
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .vxe-table--render-default .vxe-table--fixed-right-wrapper {
  box-shadow: none !important;
}
/deep/ .vxe-filter--btn {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 16px;
  position: relative;
  top: -2px;
}
.tree-page {
  position: absolute;
  width: 190px;
  left: -202px;
  top: 12px;
}
/deep/ .vxe-table--body-wrapper {
  max-height: calc(100vh - 335px) !important;
}
/* /deep/ .vxe-table--fixed-left-wrapper {
    .vxe-table--body-wrapper{
      max-height: var(--table-height) !important;
    }
  } */
.review-product:hover {
  color: var(--theme-color);
  cursor: pointer;
}
.anticon-check-circle {
  color: var(--theme-color);
}
.statistics {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
  &:hover {
    background: var(--back-color);
  }
}
.icon-list {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  top: 12px;
  i {
    &:hover {
      cursor: pointer;
    }
  }
}
.statistics-show {
  cursor: pointer;
  width: 100%;
  margin-left: -12px;
}
.statistics-menu-list {
  opacity: 0;
  height: 0;
}
/deep/ .vxe-footer--row {
  .vxe-cell--item {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .vxe-footer--column {
    &:first-child {
      .vxe-cell--item {
        opacity: 1;
        width: 120px;
      }
    }
  }
}
/deep/ .vxe-cell--sort {
  font-size: 12px !important;
  width: 1em !important;
  height: 1.35em !important;
  margin-right: 7px;
}
/deep/ .vxe-cell--filter {
  font-size: 12px !important;
  margin-top: 2px;
}
/deep/ .vxe-header--row {
  .vxe-cell {
    display: flex;
    align-items: center;
    .vxe-cell--title {
      flex: 1;
    }
  }
}
.status-img {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  .status {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
}
/deep/ .is--indeterminate .vxe-checkbox--icon::before {
  background-color: #fff !important;
}
/deep/ .vxe-checkbox--icon:hover {
  &::before {
    border-color: var(--theme-color) !important;
  }
}
/deep/ .is--checked .vxe-icon-checkbox-checked {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
  font-weight: 400 !important;
}
/deep/ .vxe-table--render-default .vxe-body--row.row--current {
  background-color: var(--back-color) !important;
}
/deep/ .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--border-line {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
}
/deep/ .is--filter-active .vxe-cell--filter .vxe-filter--btn {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table .vxe-sort--asc-btn.sort--active {
  color: var(--theme-color) !important;
}
/deep/ .is--checked {
  color: var(--theme-color) !important;
}
/deep/ .is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-option:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer button:hover {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--filter-wrapper .vxe-table--filter-footer {
  display: flex;
  justify-content: space-evenly;
  button {
    &:hover {
      color: var(--theme-color);
    }
  }
}
/* /deep/ .fixed-right--wrapper .col_3{
    opacity: 0 !important;
  } */
/deep/ .vxe-table--filter-wrapper {
  z-index: 9999;
}
/deep/ .is--indeterminate.vxe-table--filter-option {
  color: var(--theme-color) !important;
}
/deep/ .vxe-table--body-wrapper {
  .vxe-body--x-space {
    display: none;
  }
}
/deep/ .vxe-table--render-default .fixed--hidden {
  visibility: unset !important;
}
/deep/ .vxe-table--fixed-wrapper .vxe-body--row:last-child .vxe-cell {
  height: auto !important;
}
.vxe-table--fixed-left-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/deep/ .vxe-body--column {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-table--footer-wrapper {
  border-top: 1px solid rgb(233, 232, 232) !important;
}
/deep/ .vxe-header--row {
  background-color: #f8f8f9;
}
/deep/ .vxe-header--row .vxe-cell {
  font-weight: 400 !important;
}
</style>
